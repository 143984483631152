import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from "react";
import { Card } from "primereact/card"
import { handleSaveCompanyAddress } from '../Addresses/AddressDialog';
import { AddressesCard } from "../Addresses/AddressesCard";
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { ENUM_ICONS } from '../Components/Icons';
import { patchData } from "../feathers";
import logger, { logDefault } from '../Utils/logger';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { useFormik } from 'formik';
import { copyObject } from '../snippets';
import { AddressesForm, emptyAddress, validateAddress } from '../Addresses/AddressesForm';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useTranslation } from 'react-i18next';
import { useConfigurationsList, useFBiBMode } from '../Persistence/ConfigurationsContext';

const CLASSNAME = 'CompanyAddresses';
export const CompanyAddresses = ({ company, addresses, numCarousel, onUpdateCompany, readonly }) => {
	const { t } = useTranslation(['company']);
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const fbib = useFBiBMode();
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'CompanyAddresses readonly: ' + readonly)
	}, [])

	const deleteAddress = async () => {
		await patchData(ENUM_SERVICES.ADDRESSES, { ...formik.values, active: 0 }, currentUser).then(async (address) => {
			if (onUpdateCompany) {
				onUpdateCompany();
			}
		})
	}

	const patchAddress = async () => {
		logDefault(CLASSNAME, 'info', 'patchAddress: ', formik.values)
		await patchData(ENUM_SERVICES.ADDRESSES, { ...formik.values, companyId: company.id }, currentUser).then(async (address) => {
			if (onUpdateCompany) {
				onUpdateCompany();
			}
		}).catch((error) => {
			logger.error('Fehler beim Speichern einer Adresse: ' + error.message)
		});
	}

	const onDeleteAddress = (address) => {
		formik.setValues(address)
		setDisplayConfirmDialog(true);
	}

	const onEditAddress = (address) => {

		formik.setValues((address ? { ...address, postcodeAndCity: address.postcode + ' | ' + address.city } : null) || { ...copyObject(emptyAddress), origin: (fbib ? 'FBIB' : 'EAA') })
		setDisplayDialog(true);
	}

	const renderAddress = (address) => {
		return !address ? '' : <AddressesCard readonly={readonly} key={'address-card-' + address.id} address={address} onEditHandler={onEditAddress} onDeleteHandler={onDeleteAddress} />
	}

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	const formik = useFormik({
		initialValues: copyObject(emptyAddress),
		validate: validateAddress,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderHeaderAddresses = () => {
		return <div className='flex justify-content-between border-bottom-1'>
			<div className='flex font-medium  mb-2 '>{`${t('companyAddresses.headline.label')} (${addresses ? addresses.length : 0})`} </div>
			<div className={currentUser.permissions === 'READER' || readonly ? 'hidden' : 'flex w-1rem'} onClick={() => onEditAddress(null)}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
		</div>
	}

	return (
		<>
			<ConfirmDialog title='Adresse entfernen' message={`Sind Sie sicher,\nAdresse ${configurationsList.AddressTypeList.find(e => e.alias === formik.values.addressType).display}\n vom Unternehmen zu entfernen?`} labelOk='Ja'
				handleOnClick={deleteAddress} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />
			<EditDialog
				displayDialog={displayDialog}
				formik={formik}
				labelButtonOk='Ändern'
				header={formik.values.id ? 'Adresse ändern' : 'neue Adresse hinzufügen'}
				message=''
				patchForm={patchAddress}
				setDisplayDialog={setDisplayDialog}
				form={<AddressesForm
					formik={formik}
					validateForm={async () => await formik.validateForm()}
					readOnly={false}
				/>}
			/>
			<Card className='companysheet card-sheet mt-2 h-19rem' header={renderHeaderAddresses}>
				{addresses.length === 0 ? 'keine Adressen hinterlegt' : <Carousel
					className='w-full mt-10 '
					showIndicators={false}
					value={addresses}
					numVisible={numCarousel}
					numScroll={numCarousel}
					itemTemplate={renderAddress}
					responsiveOptions={responsiveOptions} />}
			</Card>
		</>
	)
}