import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditDialog } from '../../Components/Dialogs/EditDialog';
import { patchData } from '../../feathers';
import { ENUM_ROUTES } from '../../Navigation/Routes';
import { useBreadcrumbsAdd } from '../../Persistence/Breadcrumbs';
import { useConfigurationsList, useDataviewsConfig } from '../../Persistence/ConfigurationsContext';
import { useCurrentUser } from '../../Persistence/CurrentUserContext';
import { defaultProviderActivity, FBiBProviderActivitiesForm, validateProviderActivity } from './FBiBProviderActivitiesForm';
import { ENUM_DATAVIEWS_CONFIG } from '../../Enums/ENUM_DATAVIEWS_CONFIG';
import { ENUM_SERVICES } from '../../Enums/ENUM_SERVICES';
import { TemplateDataViewPagination } from '../../Components/TemplateDataViewPagination';


export const FBiBProviderActivitiesDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const currentUser = useCurrentUser();
	const [displayProviderActivityDialog, setDisplayProviderActivityDialog] = useState(false);
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.FBIBPROVIDERACTIVITIES));
	const location = useLocation()

	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.PRACTIVITY !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [configurationsList, navigate])

	const patchProviderActivity = async () => {
		await patchData(ENUM_SERVICES.FBIBPROVIDERACTIVITIES, formik.values, currentUser).then((provideractivity) => {
			addBreadcrumb(`${provideractivity.name}`, `${ENUM_ROUTES.FBIB_PROVIDERACTIVITIES}/` + provideractivity.id);
		})
	}
	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}
	const columns = [
		{ filterAlias: 'col_01', field: "displayId", style: { width: '140px' }, body: null },
		{ filterAlias: 'col_02', field: "name", style: { flexGrow: 1, flexBasis: '150px' }, body: null },
		{ filterAlias: 'col_03', field: "typeActivity", style: { flexGrow: 1, flexBasis: '200px' }, body: 'renderList' },
		{ filterAlias: 'col_04', field: "responsible", style: { flexGrow: 1, flexBasis: '150px' }, body: renderResponsible },
		{ filterAlias: 'col_05', field: "date", style: { width: '180px' }, body: 'renderDate', },
		{ filterAlias: 'col_06', field: "enddate", style: { width: '200px' }, body: 'renderDate' }
	]

	const formik = useFormik({
		initialValues: { ...defaultProviderActivity, userId: currentUser.id },
		validate: validateProviderActivity,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return (
		<div className='mx-auto eaa-dataView select-none' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<EditDialog
				form={<FBiBProviderActivitiesForm formik={formik} key='providerActivity-form' />}
				formik={formik}
				patchForm={patchProviderActivity}
				header={'Neue Trägeraktivität anlegen'}
				message={'Hier können sie eine neue Trägeraktivität anlegen'}
				setDisplayDialog={setDisplayProviderActivityDialog}
				displayDialog={displayProviderActivityDialog}
			/>
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-overviews-fbib-provideractivities'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex '
				ROUTE={ENUM_ROUTES.FBIB_PROVIDERACTIVITIES}
				columns={columns}
				dataTableWidth='100%'
				buttons={[<Button
					key='button-new-provideractivity'
					disabled={currentUser.permissions === 'READER'}
					onClick={() => { formik.setValues({ ...defaultProviderActivity, userId: currentUser.id }); setDisplayProviderActivityDialog(true) }}
					className='flex  text-white' label='Trägeraktivität anlegen' />]}
				dataviewConfig={dataviewConfig}
			/>}

		</div>
	)

}