import { useState, useEffect } from 'react';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';
import { useDataviewsConfig, useFormatDate } from '../Persistence/ConfigurationsContext';

const CLASSNAME = 'OverviewProviderActivitiesDataview'
const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES;
export const OverviewProviderActivitiesDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const formatDate = useFormatDate();
	const isAdmin = useCurrentUserIsAdmin();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES));

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	const columns = [
		{ filterAlias: 'col_01', field: "displayId", style: { width: '110px' }, body: null },
		{ filterAlias: 'col_02', field: "name", style: { flexGrow: 1, flexBasis: '150px' }, body: null },
		{ filterAlias: 'col_03', field: "typeActivity", style: { flexGrow: 1, flexBasis: '200px' }, body: 'renderList' },
		{ filterAlias: 'col_04', field: "responsible", style: { flexGrow: 1, flexBasis: '150px' }, body: renderResponsible },
		{ filterAlias: 'col_05', field: "date", style: { width: '180px' }, body: 'renderDate', },
		{ filterAlias: 'col_06', field: "endDate", style: { width: '200px' }, body: 'renderDate' },
		{ filterAlias: 'col_07', field: "participants", className: 'w-11rem', body: (rowData) => rowData.participants || '' }
	]

	return <div className='w-full overflow-auto'>
		<div className='' >
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-overviews-companyaddresses'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.PROVIDERACTIVITIES}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>}
		</div>
	</div>
}