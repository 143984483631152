import { ConfirmDialog } from '../Components/ConfirmDialog'
import { useState } from 'react'
import { format, parseISO } from 'date-fns';
import { useConfigurationsList, useFBiBMode, useFormatDate } from '../Persistence/ConfigurationsContext';
import { useBreadcrumbsUpdate } from '../Persistence/Breadcrumbs';
import { ROUTES } from '../Navigation/Routes';
import { useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from '../Components/Icons';

const numberFormat = (value) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);

export const JobOpeningCard = ({ item, onEditHandler, onDeleteHandler, readonly }) => {
	const formatDate = useFormatDate();
	const isReader = useCurrentUserIsReader();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const configurationsList = useConfigurationsList();
	const updateBreadcrumbs = useBreadcrumbsUpdate();

	const onOpenHandler = async (id) => {
		const route = ROUTES.JOBPROFILES
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + id }] })
	}

	const renderContent = () => {
		const { openingDate, jobType, salary, location, trainingCourse, status } = item
		const _jobType = (configurationsList.JobOpeningTypeList).find(entry => entry.alias === jobType);
		const _status = (configurationsList.JobOpeningStatusList).find(entry => entry.alias === status);
		return <div className="flex align-content-end flex-column h-8rem py-1 px-2 overflow-x-auto">
			<div className='grid'><div className='col-fixed w-4rem'>Status:</div><div className='col '>{_status ? _status.display : ''}</div></div>
			<div className={trainingCourse ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.TRAININGCOURSES} size={ENUM_AWESOMEICON_SIZE.XS} /></div><div className='col '>{item.trainingCourse ? item.trainingCourse.name : ''}</div></div>
			<div className={openingDate ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.CALENDAR} /></div><div className='col '>ab {formatDate(parseISO(openingDate))}</div></div>
			<div className={jobType ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.JOBTYPE} /></div><div className='col '>{_jobType ? _jobType.display : ''}</div></div>
			<div className={salary ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.EURO} /></div><div className='col '>{numberFormat(salary)}</div></div>
			<div className={location ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.LOCATION} /></div><div className='col '>{location}</div></div>
		</div>
	}

	const renderTitle = () => {
		return <div className="flex title">
			<div className="flex flex-grow-1 flex-column">
				<div className="flex font-bold">
					{item.description}
				</div>
			</div>
			<div className="flex title-icons justify-content-end font-light pt-1">
				<i className={ENUM_ICONS.EYE + 'fa-lg text-white-500'} onClick={() => { onOpenHandler(item.id) }} />
				<i className={isReader || readonly || true ? 'hidden' : (ENUM_ICONS.PENCIL + 'title-pencil')} onClick={() => { onEditHandler(item.id) }} />
				<i className={isReader || readonly || true ? 'hidden' : (ENUM_ICONS.TRASH + 'title-trash')} onClick={() => { setDisplayConfirmDialog(true) }} />
			</div>
		</div>
	}

	return (<>
		<ConfirmDialog title='Stellenprofil deaktivieren' message={`Sind Sie sicher,\nStellenprofil ${item.description}\nzu deaktivieren?'`} labelOk='Ja'
			handleOnClick={() => onDeleteHandler(item)} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

		<div className="eaa-card jobopening-card shadow-2">
			{renderTitle()}
			{renderContent()}
		</div>
	</>)
}