import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useConfigurationsList, useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { defaultProviderActivity, ProviderActivitiesForm, validateProviderActivity } from './ProviderActivitiesForm';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';

const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.PROVIDERACTIVITIES;
export const ProviderActivitiesDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const currentUser = useCurrentUser();
	const [displayProviderActivityDialog, setDisplayProviderActivityDialog] = useState(false);


	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.PRACTIVITY !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [configurationsList, navigate])

	const patchProviderActivity = async () => {
		await patchData('provideractivities', formik.values, currentUser).then((provideractivity) => {
			addBreadcrumb(`${provideractivity.name}`, `${ENUM_ROUTES.PROVIDERACTIVITIES}/` + provideractivity.id);
		})
	}
	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}
	const columns = [
		{ filterAlias: 'col_01', field: "displayId", style: { width: '110px' }, body: null },
		{ filterAlias: 'col_02', field: "name", style: { flexGrow: 1, flexBasis: '150px' }, body: null },
		{ filterAlias: 'col_03', field: "typeActivity", style: { flexGrow: 1, flexBasis: '200px' }, body: 'renderList' },
		{ filterAlias: 'col_04', field: "responsible", style: { flexGrow: 1, flexBasis: '150px' }, body: renderResponsible },
		{ filterAlias: 'col_05', field: "date", style: { width: '180px' }, body: 'renderDate', },
		{ filterAlias: 'col_06', field: "endDate", style: { width: '200px' }, body: 'renderDate' }
	]

	const formik = useFormik({
		initialValues: { ...defaultProviderActivity, userId: currentUser.id },
		validate: validateProviderActivity,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return (
		<div className='mx-auto eaa-dataView select-none' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<EditDialog
				form={<ProviderActivitiesForm formik={formik} key='providerActivity-form' />}
				formik={formik}
				patchForm={patchProviderActivity}
				header={'Neue Trägeraktivität anlegen'}
				message={'Hier können sie eine neue Trägeraktivität anlegen'}
				setDisplayDialog={setDisplayProviderActivityDialog}
				displayDialog={displayProviderActivityDialog}
			/>
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-provideractivity'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.PROVIDERACTIVITIES}
				buttons={[<Button
					key='button-new-provideractivity'
					disabled={currentUser.permissions === 'READER'}
					onClick={() => { formik.setValues({ ...defaultProviderActivity, userId: currentUser.id }); setDisplayProviderActivityDialog(true) }}
					className='flex  text-white' label='Trägeraktivität anlegen' />]}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>}
		</div>
	)

}