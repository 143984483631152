import { ENUM_ICONS } from "../../Components/Icons";
import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";

export const TrainingCourseCard = ({ trainingCourse, onEditHandler, onDeleteHandler, readonly, highlightCard }) => {
	const configurationsList = useConfigurationsList();
	const renderContent = () => {
		const { phonenumber, mobilenumber, email } = trainingCourse
		return <div className="hidden align-content-end flex-column h-5rem px-2 py-1">
			<div className={phonenumber ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.PHONE} /></div><div className='col '>{phonenumber}</div></div>
			<div className={mobilenumber ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.MOBILE} /></div><div className='col '>{mobilenumber}</div></div>
			<div className={email ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.ENVELOPE} /></div><div className='col '><a href={`mailto:${email}`}>{email}</a></div></div>
		</div>
	}
	const renderTitle = (trainingCourse) => {
		return <div className={'flex title ' + (highlightCard ? 'color-background-fbib' : '')}>
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{trainingCourse.name}
				</div>
				<div>
					{typeof trainingCourse.type === 'object' && trainingCourse.type.constructor === Object ? trainingCourse.type.display : configurationsList.TrainingCourseTypeList.find(e => e.alias === trainingCourse.type).display}
				</div>
			</div>
			<div className={readonly ? 'hidden' : "flex title-icons justify-content-end font-light"} >
				<i className={ENUM_ICONS.PENCIL + 'title-pencil'} onClick={() => { onEditHandler(trainingCourse) }} />
				<i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { onDeleteHandler(trainingCourse) }} />
			</div>
		</div>
	}

	return (<>
		{!trainingCourse || trainingCourse.active === 0 ? '' :
			<div id='relateduser-card' className={'eaa-card relateduser-card shadow-2 '}>
				<div>{renderTitle(trainingCourse)}</div>
				{renderContent(trainingCourse)}
			</div>
		}
	</>)
}