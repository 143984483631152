import { useState } from 'react'
import { CalendarChangesChecked } from "../Components/CalendarChangesChecked";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { EditorChangesChecked } from "../Components/EditorChangesChecked";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useAssociatedContactsList } from "../Persistence/AssociatedContactsContext";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { AssociatedContactChooseDialog } from '../AssociatedContacts/AssociatedContactChooseDialog';


export const emptyEAACase = { id: null, version: 1, active: 1, caseDocId: '', name: '', description: '', date: '', initiatedBy: '', status: 'OPEN', documentLocation: '', supportCaseId: '', associatedContactId: null }

export const EAACaseForm = ({ formik }) => {
	const configurationsList = useConfigurationsList();
	const associatedContactsList = useAssociatedContactsList();
	const [initiatedBy, setInitiatedBy] = useState('')

	return (
		<div>{!formik ? '' :
			< div className='col p-0 m-0' >
				<div className="formgrid grid">
					<InputChangesChecked id='name' type="text" formik={formik} label='Kurzbeschreibung / Titel' />
				</div>
				<div className="formgrid grid">
					<CalendarChangesChecked id='date' formik={formik} label='Datum' maxDate={new Date()} />
					<DropdownChangesChecked
						tabIndex={102}
						className='mr-3'
						label={'Status'}
						id={'status'}
						optionValue={'alias'}
						itemLabel={'display'}
						formik={formik}
						options={configurationsList.CaseStatusList} />
				</div>
				<div className="formgrid grid">
					<DropdownChangesChecked
						label={'Initiiert durch'}
						className='mr-5'
						id={'initiatedBy'}
						itemLabel={'display'}
						optionValue={'alias'}
						formik={formik}
						options={configurationsList.InitiatedByTypesList}
						onChange={setInitiatedBy} />


					{(formik.values.id || initiatedBy !== 'THIRDPARTY' || configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1') ? <div className="field col" /> :
						<>
							<AssociatedContactChooseDialog
								formik={formik}
								readonly={false}
								associatedContacts={[]}
								className='pl-0 mr-3'
							/>
						</>

					}
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='documentLocation' label='Dateipfad für Dokumentation' type="text" formik={formik} editmode={true} />
				</div>
				<div className={"formgrid grid"}>
					<EditorChangesChecked id='description' formik={formik} value={formik.values.description} setValue={(value) => formik.setFieldValue('description', value)} label='Erläuterung zum Fall' />
				</div>
			</div>}
		</div>

	)
}
export const validateEAACase = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Kurzbeschreibung / Titel ist ein Pflichtfeld';
	}
	if (!data.status) {
		errors.status = 'Status ist ein Pflichtfeld';
	}
	if (!data.initiatedBy) {
		errors.initiatedBy = '"Initiiert durch" ist ein Pflichtfeld';
	}
	if (!data.date) {
		errors.date = 'Datum ist ein Pflichtfeld';
	}
	if (!data.description) {
		errors.description = 'Beschreibung ist ein Pflichtfeld';
	}
	return errors;
}