import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useState, useEffect, useRef } from 'react';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import client, { patchData } from "../feathers";
import { AssociatedContactForm, getEmptyAssociatedContact, validateAssociatedContact } from "./AssociatedContactForm";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useNavigate } from "react-router-dom";
import { useConfigurationsList, useDataviewsConfig, useFBiBMode } from "../Persistence/ConfigurationsContext";
import { useErrorUpdate } from "../Persistence/ErrorContext";
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { TemplateDataViewPagination } from "../Components/TemplateDataViewPagination";
import { AssociatedOrganizationForm, getEmptyAssociatedOrganization, validateAssociatedOrganization } from "../AssociatedOrganizations/AssociatedOrganizationForm";
import { AssociatedOrganisationsProvider } from "../Persistence/AssociatedOrganisationsContext";
import { OpenEye } from "../Components/OpenEye";


const CLASSNAME = 'AssociatedContactsDataview'
export const AssociatedContactsDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.ASSOCIATEDCONTACTS));
	const fbibMode = useFBiBMode();
	const addBreadcrumb = useBreadcrumbsAdd();
	const errorUpdate = useErrorUpdate();
	const configurationsList = useConfigurationsList();
	const isReader = useCurrentUserIsReader();
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [displayOrganizationDialog, setDisplayOrganizationDialog] = useState(false);
	const [organization, setOrganization] = useState(null);
	const refCancel = useRef();

	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1') {
			errorUpdate('Modul Netzwerkkontakte', 'Das Modul Netzwerkkontakte ist in dieser Instanz nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		}
	}, [])

	const patchAssociatedOrganization = async () => {
		if (!formikOrganization.values.id) {
			const organization = { ...formikOrganization.values, origin: (fbibMode ? 'FBIB' : 'EAA') }
			return await patchData(ENUM_SERVICES.ASSOCIATEDORGANIZATIONS, organization).then((associatedOrganization) => {
				return associatedOrganization.id
			})
		} else {
			return formikOrganization.values.id
		}
	}

	const patchAssociatedContact = async () => {
		const associatedContact = { ...formik.values, origin: (fbibMode ? 'FBIB' : 'EAA') }
		setDisplayEditDialog(false)
		associatedContact.associatedOrganizationId = await patchAssociatedOrganization()
		await patchData(ENUM_SERVICES.ASSOCIATEDCONTACTS, associatedContact).then((associatedContact) => {
			addBreadcrumb(`${associatedContact.name}`, `${fbibMode ? ENUM_ROUTES.FBIB_ASSOCIATEDCONTACTS : ENUM_ROUTES.ASSOCIATEDCONTACTS}/` + associatedContact.id);
		})
	}

	const onDialogAssociatedOrganizationOK = () => {
		formikOrganization.setTouched({});
		setDisplayEditDialog(true)
	}

	const formik = useFormik({
		initialValues: getEmptyAssociatedContact(),
		validate: validateAssociatedContact,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});
	const formikOrganization = useFormik({
		initialValues: getEmptyAssociatedOrganization,
		validate: validateAssociatedOrganization,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const columns = [
		{ filterAlias: 'col_01', field: "displayId", style: { width: '110px' }, body: null },
		{ filterAlias: 'col_02', field: "name", style: { width: '200px' } },
		{ filterAlias: 'col_03', field: "organization", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ filterAlias: 'col_04', field: "email", style: { flexGrow: 1, flexBasis: '200px' } },
		{ filterAlias: 'col_05', field: "region.name", style: { width: '250px' }, body: (rowData) => { return rowData.region ? rowData.region.name : '' }, },
		{ filterAlias: 'col_06', field: "active", style: { width: '150px' }, body: 'renderTag' }

	]

	const onDoubleClick = (entry) => {
		let route = fbibMode ? ENUM_ROUTES.FBIB_ASSOCIATEDCONTACTS : ENUM_ROUTES.ASSOCIATEDCONTACTS
		if (entry.isOrganizationEntry) {
			route = fbibMode ? ENUM_ROUTES.FBIB_ASSOCIATEDORGANIZATIONS : ENUM_ROUTES.ASSOCIATEDORGANIZATIONS
		}
		const id = entry.isOrganizationEntry ? entry.associatedOrganizationId : entry.id
		addBreadcrumb(``, `${route}/` + id)
	}

	const bodyOpenLink = (rowData) => {
		let route = fbibMode ? ENUM_ROUTES.FBIB_ASSOCIATEDCONTACTS : ENUM_ROUTES.ASSOCIATEDCONTACTS
		let id = rowData.id
		if (rowData.isOrganizationEntry) {
			id = rowData.associatedOrganizationId
			route = fbibMode ? ENUM_ROUTES.FBIB_ASSOCIATEDORGANIZATIONS : ENUM_ROUTES.ASSOCIATEDORGANIZATIONS
		}
		return <OpenEye ROUTE={route} id={id} />
	}


	return <div className='mx-auto eaa-dataView select-none' style={{ maxWidth: 'calc(100vw - 100px)' }}>
		<EditDialog
			form={<AssociatedOrganisationsProvider>
				<AssociatedOrganizationForm
					formik={formikOrganization}
					validateForm={() => formikOrganization.validateForm()}
					onSelectExistingOrganization={() => {
						formikOrganization.setFieldValue('readOnly', true)
						formikOrganization.setTouched({});
					}
					}
					refBlur={refCancel}
					readOnly={formikOrganization.values.id ? true : false}
					key='associatedorganization-form' />
			</AssociatedOrganisationsProvider>}
			formik={formikOrganization}
			patchForm={onDialogAssociatedOrganizationOK}
			header={'Netzwerkorganisation auswählen oder neu anlegen'}
			message={'Hier können sie eine Netzwerkorganisation auswählen neu anlegen'}
			setDisplayDialog={setDisplayOrganizationDialog}
			displayDialog={displayOrganizationDialog}
			refCancel={refCancel}
			labelButtonOk={formikOrganization.values.id ? 'weiter' : 'Anlegen'}
		/>
		<EditDialog
			form={<AssociatedContactForm formik={formik} key='associatedcontact-form' />}
			formik={formik}
			patchForm={patchAssociatedContact}
			header={`Netzwerkkontakt zur Organisation ${formikOrganization.values.name} anlegen`}
			message={'Hier können sie einen Netzwerkkontakt neu anlegen'}
			setDisplayDialog={setDisplayEditDialog}
			additionalButton={{
				label: 'Überspringen',
				onClick: async () => {
					await patchAssociatedOrganization().then(async (associatedOrganizationId) => {
						setDisplayEditDialog(false)
						addBreadcrumb(`${formikOrganization.values.name}`, `${fbibMode ? ENUM_ROUTES.FBIB_ASSOCIATEDORGANIZATIONS : ENUM_ROUTES.ASSOCIATEDORGANIZATIONS}/` + associatedOrganizationId);
					})
				}
			}}
			displayDialog={displayEditDialog}
		/>
		<TemplateDataViewPagination
			key='datatable-associatedcontacts'
			showOpenLinkColumn={true}
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			buttons={[
				<Button
					key='button-new-associatedcontact'
					disabled={isReader}
					label='Netzwerkkontakt anlegen'
					onClick={() => { formik.setValues(getEmptyAssociatedContact); formikOrganization.setValues(getEmptyAssociatedOrganization); setDisplayOrganizationDialog(true) }}
				/>
			]}
			columns={columns}
			dataTableWidth='100%'
			dataviewConfig={dataviewConfig}
			routeIdField='linkDataview'
			onDoubleClick={onDoubleClick}
			bodyOpenLink={bodyOpenLink}
			ROUTE={fbibMode ? (ENUM_ROUTES.FBIB_ASSOCIATEDORGANIZATIONS) : (ENUM_ROUTES.ASSOCIATEDORGANIZATIONS)}
		/>
	</div>
}