
import { useEffect, useState } from "react";
import { EditorChangesChecked } from '../../Components/EditorChangesChecked';
import { InputChangesChecked } from '../../Components/InputChangesChecked';
import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";
import { useCurrentUser, useCurrentUserIsReader } from '../../Persistence/CurrentUserContext';
import { logDefault } from '../../Utils/logger';
import { DropdownChangesChecked } from "../../Components/DropdownChangesChecked";
import { Address } from "../../Components/FormElements/Adddress";

export const emptyTrainingCenter = { id: null, version: 1, active: 1, name: '', description: '', type: '', userId: null, line1: '', line2: '', line3: '', postcode: '', city: '', country: 'Deutschland', mail: '', phone: '', mobile: '', website: '' }

const CLASSNAME = 'TrainingCenterForm'
export const TrainingCenterForm = ({ formik, validateForm, readonly }) => {
	const isReader = useCurrentUserIsReader();
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik values:', formik.values)
		setMounted(true)
	}, [isReader, formik.values])



	return (
		<div className='provider-activities-form col' >
			{!formik ? '' : <div className="formgrid grid">
				<div className="field col  mb-0">
					<div className="formgrid grid">
						<InputChangesChecked
							tabIndex={10}
							id='name'
							type="text"
							formik={formik}
							label='Name der Ausbildungsstätte' />
						<DropdownChangesChecked
							tabIndex={20}
							options={configurationsList.TrainingCenterTypeList}
							disabled={false} formik={formik}
							id='type'
							optionValue='alias'
							itemLabel='display'
							className='mr-3'
							label='Typ' />
					</div>
					<div className="formgrid grid">
						<InputChangesChecked tabIndex={30} formik={formik} id='mail' label='E-Mail' type='text' />
						<InputChangesChecked tabIndex={40} formik={formik} id='phone' label='Telefon' type='text' />
					</div>
					<div className="formgrid grid">
						<InputChangesChecked tabIndex={50} formik={formik} id='mobile' label='Mobil' type='text' />
						<InputChangesChecked tabIndex={60} formik={formik} id='website' label='Web-Seite' type='text' />
					</div>
					<Address readonly={readonly} formik={formik} validateForm={validateForm} className='field col p-0 mr-3' />
					<div className="formgrid grid">
						{!mounted ? '' : <EditorChangesChecked
							id='description'
							tabIndex={1000}
							value={formik.values.description}
							setValue={(value) => formik.setFieldValue('description', value)}
							label='Beschreibung der Ausbildungsstätte'
							required={false} />}
					</div>
				</div>
			</div>}
		</div>

	)
}


export const validateTrainingCenter = (data) => {
	let errors = {};

	if (!data.name) {
		errors.name = 'Der Name der Ausbildungsstätte muss gesetzt sein!';
	}
	if (!data.type) {
		errors.type = 'Der Typ der Ausbildungsstätte muss gesetzt sein!';
	}
	return errors;
}