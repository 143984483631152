import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { ENUM_ROUTES } from "../../Navigation/Routes";
import { logDefault } from "../../Utils/logger";
import { deleteData, getData, patchData } from "../../feathers";

const CLASSNAME = 'TrainingCourse';
const servicename = ENUM_SERVICES.TRAININGCOURSE;

export function getTypeTrainingCourseObject(configurationsList: any, type: any) {
	return configurationsList.TrainingCourseTypeList.find((e: any) => e.alias === type) || { display: 'nicht besetzt' }
}


export const openTrainingCourse = async (id: number, addBreadcrumb: Function) => {
	logDefault(CLASSNAME, 'info', 'openTrainingCourse id:' + id, null)
	await getData(servicename, id).then((trainingCourse) => {
		addBreadcrumb(`${trainingCourse.name}`, `${ENUM_ROUTES.FBIB_TRAININGCOURSES}/` + id);
	})
}

export const deleteTrainingCourse = async (trainingCourse: any, minusBreadcrumbs: Function, setToastParams: Function) => {
	await deleteData(servicename, trainingCourse.id).then(async () => {
		minusBreadcrumbs();
	}).catch((error) => {
		setToastParams({ title: 'Ausbildungsgang konnte nicht gelöscht werden', message: error.message })
	})
}

export const toggleTrainingCourseStatus = async (trainingCourse: any, currentUser: any, isReader: any, setTrainingCourse: Function, setReadonly: Function, setToastParams: Function) => {
	trainingCourse.active = trainingCourse.active ? 0 : 1;
	return await patchTrainingCourse(trainingCourse, currentUser, isReader, setTrainingCourse, setReadonly, setToastParams)
}
export const patchTrainingCourse = async (trainingCourse: any, currentUser: any, isReader: any, setTrainingCourse: Function, setReadonly: Function, setToastParams: Function) => {

	logDefault(CLASSNAME, 'info', 'trainingCenter:', trainingCourse)
	return await patchData(servicename, trainingCourse, currentUser).then((trainingCourse) => {
		if (setTrainingCourse) {
			setTrainingCourse(trainingCourse);
		}
		if (setReadonly) {
			setReadonly(!trainingCourse.active || isReader)
		}
		return trainingCourse;
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'Ausbildungsgang mit der Nummer "' + trainingCourse.displayId + '" konnte nicht aktualisiert werden', message: error.message })
		} else {
			throw error
		}
	});
}

export const getTrainingCourse = async (id: any, isReader: any, setTrainingCourse: Function, setReadonly: Function, setToastParams: Function) => {
	return await getData(servicename, id).then((trainingCourse) => {
		if (setTrainingCourse) {
			setTrainingCourse(trainingCourse);
		}
		if (setReadonly) {
			setReadonly(!trainingCourse.active || isReader)
		}
		return trainingCourse;
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'Ausbildungsgang mit der Nummer "' + id + '" konnte nicht ermittelt werden', message: error.message })
		} else {
			throw error
		}
	});
}
