import { useEffect, useState } from "react";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { findData } from "../../feathers";
import { DropdownChangesChecked } from "../../Components/DropdownChangesChecked";

export const TrainingCourseDropdown = ({ formik, readonly, handleTrainingCourseChange, handleTrainingCourseBlur }) => {
	const [mounted, setMounted] = useState(true)
	const [trainingCourseList, setTrainingCourseList] = useState();

	useEffect(() => {
		findData(ENUM_SERVICES.TRAININGCOURSE, { active: 1 }).then((trainingCourseList) => {
			setTrainingCourseList(trainingCourseList);
			setMounted(true);
		})
	}, [])

	return (!mounted ? '' :
		<DropdownChangesChecked
			editmode={true}
			formik={formik}
			id='trainingCourseId'
			optionValue={'id'}
			itemLabel='name'
			filter={true}
			showClear={false}
			filterBy="name"
			label='Name des Ausbildungsgangs'
			options={trainingCourseList}
			className='mr-3'
			key='trainingCourse' />
	)
}