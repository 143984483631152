import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from "react";
import { findData, getData, patchData } from "../feathers";
import { logDefault } from "../Utils/logger";
import { ENUM_ICONS } from '../Components/Icons';
import { Card } from 'primereact/card';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { FBiBJobOpeningCard } from '../FBiB/FBiBJobOpenings/FBiBJobOpeningCard';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { getFBiBJobOpening, patchFBiBJobOpening } from '../FBiB/FBiBJobOpenings/FBiBJobOpeningManager';
import { FBiBJobOpeningForm, emptyFBiBJobOpening, validateFBiBJobOpening } from '../FBiB/FBiBJobOpenings/FBiBJobOpeningForm';
import { copyObject, getSortedList } from '../snippets';
import { useFormik } from 'formik';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { FBiBJobOpeningStatusForm } from '../FBiB/FBiBJobOpenings/FBiBJobOpeningStatusForm';

const CLASSNAME = 'CompanyFBiBJobOpenings';
export const CompanyFBiBJobOpenings = ({ company, numCarousel, onUpdateCompany, readonly, fbibCaseMode, fbibCaseId }) => {
	const currentUser = useCurrentUser()
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [displayEditStatusDialog, setDisplayEditStatusDialog] = useState(false);
	const [jobOpenings, setJobOpenings] = useState([]);
	const [fbibCaseJobOpeningId, setFbibCaseJobOpeningId] = useState(null);

	useEffect(() => {

		const jobService = ENUM_SERVICES.FBIBJOBPROFILES;
		const query = { $rawQuery: `fbib_jobopenings.companyId = ${company.id} AND fbib_jobopenings.status NOT IN ('INACTIVE', 'CLOSED')` }

		findData(jobService, query).then((result) => {
			const listJobOpenings = []
			setFbibCaseJobOpeningId(null)
			for (let entry of (result || [])) {
				if (fbibCaseId === entry.fbibCaseId || entry.status !== 'ARRANGED') {
					listJobOpenings.push({ ...entry, sortIndex: fbibCaseId === entry.fbibCaseId })
				}
				if (entry.fbibCaseId === fbibCaseId) {
					setFbibCaseJobOpeningId(entry.id)
				}
			}
			setJobOpenings(getSortedList(listJobOpenings, 'sortIndex', true))
		})

	}, [company])


	const handlerEdit = async (jobOpeningId) => {
		await getFBiBJobOpening(jobOpeningId).then((jobOpening) => {
			formik.setValues({ ...jobOpening, postcodeAndCity: jobOpening.postcode + ' | ' + jobOpening.city })
			setDisplayEditDialog(true)
		})
	}

	const handlerNewFBiBJobOpening = async () => {
		const fbibCase = !fbibCaseId ? null : await getData(ENUM_SERVICES.FBIBCASES, fbibCaseId)
		formik.setValues({ ...copyObject(emptyFBiBJobOpening), companyId: company.id, company, fbibCaseId, fbibCase, status: 'RESERVED' })
		setDisplayEditDialog(true)
	}

	const handleEditStatus = async (jobOpeningId) => {
		const fbibCase = await getData(ENUM_SERVICES.FBIBCASES, fbibCaseId)
		await getData(ENUM_SERVICES.FBIBJOBPROFILES, jobOpeningId).then((jobOpening) => {
			formikStatus.setValues({ id: jobOpening.id, version: jobOpening.version, fbibCaseName: fbibCase ? fbibCase.name : '', currentStatus: jobOpening.status })
			setDisplayEditStatusDialog(true)
		})
	}

	const patchEditStatus = async () => {
		const jobOpening = { ...formikStatus.values, fbibCaseId: ['ARRANGED', 'RESERVED'].includes(formikStatus.values.status) ? fbibCaseId : null }
		await patchData(ENUM_SERVICES.FBIBJOBPROFILES, jobOpening).then(() => {
			onUpdateCompany();
		})
	}

	const handlerDelete = async (jobOpening) => {
		jobOpening.active = 0
		logDefault(CLASSNAME, 'info', 'handlerDelete', jobOpening)
		await patchData(ENUM_SERVICES.FBIBJOBPROFILES, jobOpening).then(() => {
			onUpdateCompany();
		})
	}

	const renderListEntries = (item) => {
		return <FBiBJobOpeningCard
			key={'jobopening_' + item.id}
			item={item}
			onEditStatus={handleEditStatus}
			onEditHandler={handlerEdit}
			onDeleteHandler={handlerDelete}
			readonly={readonly}
			fbibCaseMode={fbibCaseMode}
			fbibCaseId={fbibCaseId}
			fbibCaseJobOpeningId={fbibCaseJobOpeningId}
		/>
	}
	const renderHeaderJobopenings = () => {
		return <div className='flex justify-content-between border-bottom-1'>
			<div className='flex font-medium  mb-2 '>Stellenprofile ({jobOpenings.length}) </div>
			<div className={readonly || fbibCaseJobOpeningId ? 'hidden' : 'flex w-1rem'} onClick={handlerNewFBiBJobOpening}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
		</div>
	}

	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Stellenprofile</div>
			{renderHeaderJobopenings()}
		</div>
	}

	/** formik FBiB Jobopening */
	const formik = useFormik({
		initialValues: copyObject(emptyFBiBJobOpening),
		validate: validateFBiBJobOpening,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	/** formik FBiB Jobopening STATUS */
	const formikStatus = useFormik({
		initialValues: { id: null, version: 1, status: '', fbibCaseName: '', currentStatus: 'OPEN' },
		validate: (data) => {
			let errors = {};
			if (!data.status) {
				errors.status = 'Neuer Status muss gesetzt werden';
			}
			return errors;
		},
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	return (
		<>
			<Card className="companysheet card-sheet bg-white h-19rem" header={renderHeader}>
				<Carousel
					circular={false}
					className='flex w-full mt-1 '
					contentClassName='flex justify-content-center w-full'
					showNavigators={jobOpenings.length > 1}
					showIndicators={false}
					value={jobOpenings}
					numVisible={numCarousel}
					numScroll={numCarousel}
					itemTemplate={renderListEntries}
					responsiveOptions={responsiveOptions} />
				<EditDialog
					displayDialog={displayEditDialog}
					formik={formik}
					labelButtonOk={formik.values.id ? 'Speichern' : 'Anlegen'}
					header={'FBiB Stellenprofil anlegen'}
					message={'Lege ein neues FBiB Stellenprofil an'}
					patchForm={async () => {
						await patchFBiBJobOpening(formik.values, currentUser).then(async () => {
							await onUpdateCompany()
						})
					}}
					setDisplayDialog={setDisplayEditDialog}
					form={<FBiBJobOpeningForm
						formik={formik}
						validateForm={() => formik.validateForm()}
					/>}
				/>
				<EditDialog
					displayDialog={displayEditStatusDialog}
					formik={formikStatus}
					labelButtonOk={'Ändern'}
					header={'Status FBiB Stellenprofil ändern'}
					message={'Ändere den Status des FBiB Stellenprofils'}
					patchForm={async () => {
						await patchEditStatus()
					}}
					setDisplayDialog={setDisplayEditStatusDialog}
					form={<FBiBJobOpeningStatusForm formik={formikStatus} validateForm={() => { }} />}
				/>
			</Card>
		</>
	)
}
