import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useState } from 'react';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { ENUM_ICONS } from "../Components/Icons";
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { logDefault } from "../Utils/logger";
import { getData, patchData } from "../feathers";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { CaseRequestTypeForm, getEmptyCaseRequestType, validateCaseRequestType } from "./CaseRequestTypesForm";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { useDataviewsConfig } from "../Persistence/ConfigurationsContext";
import { TemplateDataViewPagination } from "../Components/TemplateDataViewPagination";

const CLASSNAME = 'CaseRequestTypesDataview'

export const CaseRequestTypesDataview = ({ handleStatusChange }) => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.CASEREQUESTTYPES));
	const isAdmin = useCurrentUserIsAdmin();
	const [refreshTime, setRefreshTime] = useState(null)
	const [displayCaseRequestTypeDialog, setDisplayCaseRequestTypeDialog] = useState(false);

	const patchCaseRequestType = async () => {
		await patchData(ENUM_SERVICES.CASEREQUESTTYPES, formikCaseRequestTypes.values).then(() => {
			setDisplayCaseRequestTypeDialog(false)
			setRefreshTime(Date.now())
		})
	}

	const formikCaseRequestTypes = useFormik({
		initialValues: getEmptyCaseRequestType(),
		validate: validateCaseRequestType,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const onEditCaseRequestType = async (id) => {
		await getData(ENUM_SERVICES.CASEREQUESTTYPES, id).then((caseRequestType) => {
			logDefault(CLASSNAME, 'info', 'onEditOrganization organization:', caseRequestType)
			formikCaseRequestTypes.setValues(caseRequestType);
			setDisplayCaseRequestTypeDialog(true)
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Antragsart bearbeiten', icon: ENUM_ICONS.PENCIL, command: () => { onEditCaseRequestType(rowData.id) } },
		{ label: rowData.active ? 'Inaktiv setzen' : 'Aktiv setzen', icon: (rowData.active ? ENUM_ICONS.DEACTIVATE : ENUM_ICONS.REACTIVATE), command: () => { handleStatusChange(rowData, ENUM_SERVICES.CASEREQUESTTYPES, 'Antragsart') } }]

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const columns = [
		{ filterAlias: 'col_02', field: "name", style: { flexGrow: 1, flexBasis: '300px' } },
		{ filterAlias: 'col_03', field: "listentry", style: { flexGrow: 1, flexBasis: '100px' } },
		{ filterAlias: 'col_04', field: "active", style: { width: '200px' }, body: 'renderTag' },
		{ style: { width: '30px' }, body: renderContextMenu },
	]

	return <div className='flex flex-column gap-2'>
		<EditDialog
			form={<CaseRequestTypeForm formik={formikCaseRequestTypes} key='caserqeuesttype-form' />}
			formik={formikCaseRequestTypes}
			patchForm={patchCaseRequestType}
			header={formikCaseRequestTypes.values.id ? 'Antragsart ändern' : 'Antragsart neu anlegen'}
			message={formikCaseRequestTypes.values.id ? 'Hier können sie die Antragsart: ' + formikCaseRequestTypes.values.name + ' ändern.' : 'Hier können sie eine Antragsart neu anlegen'}
			setDisplayDialog={setDisplayCaseRequestTypeDialog}
			displayDialog={displayCaseRequestTypeDialog}
		/>
		<TemplateDataViewPagination
			key='datatable-caseRequestTypes'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			buttons={[<Button
				key='button-new-caserequesttype'
				disabled={!isAdmin}
				label='Antragsart anlegen'
				onClick={() => { formikCaseRequestTypes.setValues(getEmptyCaseRequestType()); setDisplayCaseRequestTypeDialog(true) }} />]}
			columns={columns}
			dataTableWidth='100%'
			refreshTime={refreshTime}
			dataviewConfig={dataviewConfig}
		/>
	</div>
}